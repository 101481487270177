import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Partners from "./partials/Partners";
import Portfolio from "./partials/Portfolio";
import React from "react";
import Testimonials from "./partials/Testimonials";
import Welcome from "./partials/Welcome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import CurrentEstateListWithoutFilter from "../estates/CurrentEstateListWithoutFilter";

const Start = () => {
  const welcomeSection = React.useRef(null);

  const goToWelcomeSection = () => {
    window.scrollTo({
      top: welcomeSection.current.offsetTop - 200,
      behavior: "smooth",
    });
  };

  return (
    <div className="text-center">
      <section className="scroll-hero">
        <Button onClick={goToWelcomeSection}>
          <FontAwesomeIcon icon={faAngleDown} style={{ maxHeight: "42px" }} />
        </Button>
      </section>
      <section ref={welcomeSection} className="bg-di-secondary welcome">
        <Welcome />
      </section>
      <section className="">
        <CurrentEstateListWithoutFilter />
      </section>
      <section className="testimonials bg-light-gray">
        <Testimonials />
      </section>
      <section className="portfolio bg-di-primary">
        <Portfolio golden={true} title="Unsere Leistungen" />
      </section>
      <section className="partners bg-light-gray">
        <Partners small={true} />
      </section>
    </div>
  );
};

export default Start;
