import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { FilterAction, FilterState } from "../../types/filter";
import sortOptions, { SortKey, SortOption } from "../../types/sort";
import {
  COOKIE_NAME,
  COOKIE_PREFIX,
  bake_cookie,
  read_cookie,
} from "../context/Cookie";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";
import Estate from "./Estate";

const CurrentEstateListWithoutFilter = () => {
  const currentEstates: FilterState = React.useContext(GlobalStateContext);

  const dispatch = React.useContext(GlobalDispatchContext);

  const sortOptionsOfferDesc = sortOptions.filter(
    (option) => option.key === SortKey.NewOffersDesc
  )[0] as unknown as SortOption;

  useEffect(() => {
    if (read_cookie(COOKIE_NAME))
      bake_cookie(`${COOKIE_PREFIX}sortOption`, sortOptionsOfferDesc);
    dispatch({
      type: FilterAction.SortOption,
      value: sortOptionsOfferDesc,
    });
  }, [dispatch, sortOptionsOfferDesc]);
  return (
    <>
      <Container>
        <h2 className="gold-header">Aktuelle Immobilien</h2>
      </Container>
      <section className="mx-4 mb-4 flex-wrapper">
        {currentEstates.estatePage.length === 0 ? (
          <p className="text-center">
            Es wurden keine verfügbaren Objekte gefunden.
          </p>
        ) : (
          <>
            {currentEstates.estatePage.map((estate, index) => {
              if (estate.ind_2038_Feld_ObjTech66 === "ind_Schl_3021") {
                return (
                  <Estate key={index} estate={estate} showDetails={true} />
                );
              }
              return null; // Return null for skipped items
            })}
          </>
        )}
      </section>
    </>
  );
};

export default CurrentEstateListWithoutFilter;
