import { Container } from "react-bootstrap";
import React from "react";

const Welcome = () => {
  return (
    <Container>
      <h2 className="gold-header">Herzlich Willkommen</h2>
      <p className="line-height-2 center-left">
        Wenn Sie beim Besichtigungstermin von Kaffeeduft und Loungemusik
        empfangen werden, dann haben Sie sich wahrscheinlich nicht in der Tür
        geirrt, sondern einen Termin mit Detharding Immobilien! Ob Sie ein neues
        Eigenheim suchen, eine lieb gewonnene Immobilie verkaufen möchten oder
        Mieter suchen, die wirklich zu Ihnen passen: Wir setzen uns mit Know-how
        und Herzblut dafür ein, Ihre Wünsche zu erfüllen und kümmern uns für Sie
        um jedes Detail. Damit Sie von Anfang an das gute Gefühl genießen, genau
        den richtigen Immobilienpartner gefunden zu haben!
      </p>
    </Container>
  );
};

export default Welcome;
