import Layout from "../components/layout/Layout";
import React from "react";
import Start from "../components/sections/Start";

const StartPage = () => {
  return (
    <Layout page="index">
      <Start />
    </Layout>
  );
};

export default StartPage;
